import { memo, useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio';
import filter from 'lodash/filter';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { useParseOptionsContractMonthString } from '~/modules/options/utils/useParseOptionsContractMonthString';
import { optionAnalyzeStore } from '~/pages/daddy960_opkevin/component/optionAnalyze/optionAnalyzeStore';
import { filterSocket } from '~/modules/options/utils/filterSocketUtil';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { SignalrTopic } from '~/modules/SDK/Signalr/SignalrTopic';
import { fill_horizontal_all_center, fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { css } from '@emotion/react';
import { useMount } from 'react-use';
import getSymbolPrice from '~/modules/options/utils/getSymbolPrice';
import { Table } from '@mantine/core';
import { horizontalScrollbarCss } from '~/css/scrollbarCss';
import { useOptionReferencePrice } from '~/pages/daddy960_opkevin/component/optionAnalyze/useOptionReferencePrice';
import { getWeeklyMxfContract } from '~/pages/daddy960_opkevin/component/optionAnalyze/useGetOptionContract';
import styled from '@emotion/styled';
import { store } from '~/pages/heineken_template/_private/store';
import { goodpsy_store } from '~/pages/goodpsy/goodpsy_store';
const emptyCallDataValue = {
    changeCall: '-',
    closeCall: '-',
    volumeCall: '-',
    timeValueCall: '-',
    tradeCall: '-',
    bidCall: '-',
    askCall: '-',
    prevCall: '-',
};
const emptyPutDataValue = {
    changePut: '-',
    closePut: '-',
    volumePut: '-',
    timeValuePut: '-',
    tradePut: '-',
    bidPut: '-',
    askPut: 0,
    prevPut: '-',
};
/** 價平欄位背景顏色 */
const atTheMoneyPriceMark = (strikePrice, atTheMoneyPrice_) => {
    if (Number(strikePrice) === atTheMoneyPrice_) {
        return '#008aff22';
    }
    else
        return '#00000000';
};
const quoteColor = (change) => {
    if (Number(change) > 0) {
        return '#ff0000';
    }
    else if (Number(change) < 0) {
        return '#00aa00';
    }
    else
        return '#252525';
};
const bidAskColor = (quote, prev) => {
    if (quote > prev) {
        return '#ff0000';
    }
    else if (quote < prev) {
        return '#00aa00';
    }
    else
        return '#252525';
};
const contractAbb = (contract) => {
    if (!contract?.includes('W'))
        return 'O';
    if (contract?.includes('W'))
        return contract.slice(-1);
};
//給予TVchart選擇權代號
const putChange = (contract, monthCode, element) => {
    store.charting.changeSymbol('TX' + contractAbb(contract) + element.symbolCall + monthCode + '3');
};
//給予TVchart選擇權代號
const callChange = (contract, monthCode, element) => {
    store.charting.changeSymbol('TX' + contractAbb(contract) + element.symbolCall + monthCode + '3');
};
export const OptionQuoteTable = memo(function OptionQuoteTable(props) {
    const state = useSnapshot(optionAnalyzeStore);
    const contract = state.currentContract;
    const socket_ = useSignalrStoreValueOHLC(state_ => state_.value);
    const source = Object.values(socket_);
    useMount(() => {
        useSignalrStore.getState().subscribeAddTradeInfoTopic([SignalrTopic.tw_options]);
        useSignalrStore.getState().subscribeAddTopic([SignalrTopic.tw_options]);
    });
    //合約月份
    const contract_ = useParseOptionsContractMonthString(contract);
    //call data
    const callSource = filter(source, datum => filterSocket(datum, 'CALL', contract_));
    //put data
    const putSource = filter(source, datum => filterSocket(datum, 'PUT', contract_));
    //call A~L的月份代號
    const callAsciiMonth = callSource.map(s => s?.symbol)[0]?.slice(-2, -1) ?? '';
    //put M~X的月份代號
    const putAsciiMonth = putSource.map(s => s?.symbol)[0]?.slice(-2, -1) ?? '';
    //合併所有買權、賣權之後，再取得五碼的履約價
    const strikePrices = callSource
        .concat(putSource)
        .map(socketDatum => getSymbolPrice(socketDatum?.symbol))
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort((a, b) => a - b);
    //call資料
    const dataCall = strikePrices.map(symbol => {
        const item = callSource?.find(s => s?.symbol?.slice(3, -2) === String(symbol));
        return item
            ? {
                symbolCall: item.symbol.slice(3, -2),
                changeCall: ((item?.close ?? 0) - (item?.prevRef ?? 0)).toFixed(2),
                closeCall: item?.close,
                volumeCall: item?.volume,
                timeValueCall: 0,
                tradeCall: item?.qty,
                bidCall: item?.bid,
                askCall: item?.ask,
                prevCall: item?.prevRef,
            }
            : {
                symbolCall: String(symbol),
                ...emptyCallDataValue,
            };
    });
    //put資料
    const dataPut = strikePrices.map(symbol => {
        const item = putSource?.find(s => s?.symbol?.slice(3, -2) === String(symbol));
        return item
            ? {
                symbolPut: item.symbol.slice(3, -2),
                changePut: ((item?.close ?? 0) - (item?.prevRef ?? 0)).toFixed(2),
                closePut: item?.close,
                volumePut: item?.volume,
                timeValuePut: 0,
                tradePut: item?.qty,
                bidPut: item?.bid,
                askPut: item?.ask,
                prevPut: item?.prevRef,
            }
            : {
                symbolPut: String(symbol),
                ...emptyPutDataValue,
            };
    });
    const dataMerge = dataCall?.reduce((newData, callObj) => {
        const matchingPut = dataPut?.find(putObj => putObj.symbolPut === callObj.symbolCall);
        if (matchingPut) {
            newData.push({
                ...callObj,
                ...matchingPut,
            });
        }
        return newData;
    }, []) ?? [];
    /** 所有履約價 */
    const allStrikePrice = source.length ? source.map(s => Number(s?.symbol.slice(3, -2))) : [0];
    const mtx = getWeeklyMxfContract(state.currentContract);
    const currentClose = useOptionReferencePrice(mtx);
    /** 價平位置 */
    const atTheMoneyPrice = allStrikePrice.reduce((prev, curr) => {
        return Math.abs(curr - currentClose) < Math.abs(prev - currentClose) //&& currentClose >= curr
            ? curr
            : prev;
    });
    const tableRef = useRef(null);
    const rowRefs = useRef([]);
    const scrollAreaRef = useRef(null);
    const numberAtIndex = dataMerge.map(s => Number(s.symbolCall)).indexOf(atTheMoneyPrice);
    useEffect(() => {
        if (tableRef.current && rowRefs.current[numberAtIndex]) {
            rowRefs.current[numberAtIndex]?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [numberAtIndex]);
    const strToNum = (volume_) => {
        if (volume_ === '-') {
            return 0;
        }
        else
            return Number(volume_);
    };
    //使用成交量排序 這邊需求較為特殊
    //將排序邏輯拆出來放置這邊 未來通用元件不會用到成交量排序
    const sortType = useSnapshot(goodpsy_store).optionVolumeSort;
    //根據排序方式給予對應的排序結果
    const sortOptionData = () => {
        if (sortType === 'call') {
            return dataMerge.sort((a, b) => strToNum(b.volumeCall) - strToNum(a.volumeCall));
        }
        else if (sortType === 'put') {
            return dataMerge.sort((a, b) => strToNum(b.volumePut) - strToNum(a.volumePut));
        }
        else if (sortType === 'total') {
            return dataMerge.sort((a, b) => strToNum(b.volumeCall) +
                strToNum(b.volumePut) -
                (strToNum(a.volumeCall) + strToNum(a.volumePut)));
        }
        else
            return dataMerge;
    };
    const rows = sortOptionData()?.map((element, index) => (<tr ref={el => (rowRefs.current[index] = el)} key={element.symbolCall} css={css `
          ${fill_horizontal_all_center};
          display: grid;
          grid-template-columns: repeat(11, 9%);
          height: 30px;
          background-color: ${atTheMoneyPriceMark(element.symbolCall, atTheMoneyPrice)} !important;
          & > * {
            ${fill_horizontal_all_center};
            width: 64px;
          }
          & > * {
            width: 100%;
            height: 26px;
            border-top: 0px !important;
            cursor: pointer;
          }
          &:hover {
            background-color: #f6f6f6 !important;
          }
        `}>
        <td onClick={() => {
            callChange(contract, callAsciiMonth, element);
        }}>
          {element.volumeCall}
        </td>
        <td onClick={() => {
            callChange(contract, callAsciiMonth, element);
        }} css={css `
            color: ${bidAskColor(element.bidCall, element.prevCall)};
          `}>
          {element.bidCall}
        </td>
        <td onClick={() => {
            callChange(contract, callAsciiMonth, element);
        }} css={css `
            color: ${bidAskColor(element.askCall, element.prevCall)};
          `}>
          {element.askCall}
        </td>
        <td onClick={() => {
            callChange(contract, callAsciiMonth, element);
        }} css={css `
            color: ${quoteColor(element.changeCall)};
          `}>
          {element.changeCall}
        </td>
        <td onClick={() => {
            callChange(contract, callAsciiMonth, element);
        }} css={css `
            color: ${quoteColor(element.changeCall)};
          `}>
          {element.closeCall}
        </td>
        <td css={css `
            background-color: #f9f9f9;
          `}>
          {element.symbolCall}
        </td>
        <td onClick={() => {
            putChange(contract, putAsciiMonth, element);
        }} css={css `
            color: ${quoteColor(element.changePut)};
          `}>
          {element.closePut}
        </td>
        <td onClick={() => {
            putChange(contract, putAsciiMonth, element);
        }} css={css `
            color: ${quoteColor(element.changePut)};
          `}>
          {element.changePut}
        </td>
        <td onClick={() => {
            putChange(contract, putAsciiMonth, element);
        }} css={css `
            color: ${bidAskColor(element.askPut, element.prevPut)};
          `}>
          {element.askPut}
        </td>
        <td onClick={() => {
            putChange(contract, putAsciiMonth, element);
        }} css={css `
            color: ${bidAskColor(element.bidPut, element.prevPut)};
          `}>
          {element.bidPut}
        </td>
        <td onClick={() => {
            putChange(contract, putAsciiMonth, element);
        }}>
          {element.volumePut}
        </td>
      </tr>));
    return (<tableStyleds.container ref={scrollAreaRef}>
        {sortOptionData().length === 0 ? (<tableStyleds.loading>Loading...</tableStyleds.loading>) : (<Table highlightOnHover striped css={css `
              width: 100%;
              min-width: 700px;
              height: 100%;
              font-family: Roboto, Helvetica, Arial, sans-serif;
            `} ref={tableRef}>
            <thead>
              <tableStyleds.header>
                <th>成交量</th>
                <th>買進</th>
                <th>賣出</th>
                <th>漲跌</th>
                <th>成交</th>
                <th>履約價</th>
                <th>成交</th>
                <th>漲跌</th>
                <th>賣出</th>
                <th>買進</th>
                <th>成交量</th>
              </tableStyleds.header>
            </thead>
            <tableStyleds.body>{rows}</tableStyleds.body>
          </Table>)}
      </tableStyleds.container>);
});
const tableStyleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    overflow-x: auto;
    gap: 16px;
    font-weight: 600;
  `,
    loading: styled.div `
    ${fill_horizontal_all_center};
  `,
    header: styled.tr `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: repeat(11, 9%);
    height: 38px;
    width: 100%;
    background-color: #f7f7f7;
    & > * {
      ${fill_horizontal_all_center};
    }
  `,
    body: styled.tbody `
    width: 100%;
    ${horizontalScrollbarCss};
    flex-direction: column;
  `,
};
